.collateral-tabs {
  .product-gpsr {
    dt {
      position: static;
      width: auto;
      height: auto;
      overflow: inherit;
    }

    dd {
      margin-bottom: 1em;
      margin-left: 2em;
    }
  }
  .gpsr-risk {
    margin-bottom: 30px;
  }
}