.price-box {
    margin-top: 1rem;
    color: @text-3;
    line-height: 1.2;
    font-size: 1.2rem;

    > .price-container,
    &.price-final_price {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        width: 100%;
    }
		.price {
			font-size: 1.8rem;
		}

    &.price-final_price {
        flex-wrap: wrap;
    }

    .price-excluding-tax {
        text-align: right;
    }

    .price-including-tax {
        text-align: left;

        &:before,  + .price-excluding-tax:before {
            content: attr(data-label);
            display: block;
            font-size: 1.2rem;
            line-height: 1.1;
            margin-bottom: @padding-mini;
        }

        .price {
            font-size: 2rem;
        }
    }

    .special-price, .old-price {
        width: 100%;
    }

    .special-price {
        .price-wrapper {
            width: 50%;
        }

        .price-label {
            display: none;
        }

        .price-container {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            width: 100%;
        }

        .price-excluding-tax {
            text-align: right;

            .price {
                font-size: 1.1rem;
                color: @text-1;
            }
        }
    }

    .old-price {
        .price-label {
            display: none;
        }

        .price-including-tax {
            text-align: left;

            &:before {
                content: none;
            }
        }

        .price {
            font-size: 1.6rem;
            text-decoration: line-through;
            padding-top: .5rem;
						color: @text-2;
        }
    }

    .price-label {
        display: block;
        line-height: 1.1;
        margin-bottom: @padding-mini;
    }

    .price {
        display: block;
        font-weight: 900;
        color: @text-1;
        white-space: nowrap;
        line-height: 1;

        &::before {
            display: block;
            line-height: 1.1;
            margin-bottom: @padding-mini;
        }
    }
}

.item, .product-item {
	.slider-recommended &,
	.product-items & {
		display: block;
		width: 320px;
		max-width: 100%;
		position: relative;
		border: 1px solid @rgba-black;
		background-color: @white;
		padding-bottom: @addtocart-h;
		transition: box-shadow @transition;

		a {
			text-decoration: none;
			color: @text-1;
		}

		.product-item-details {
			padding: @padding-mobile + @padding-mini;
            display: block;
		}

        .product-item-info {
            display: block;
            width: 100%;
            height: 100%;
        }

		.product-item-photo {
			width: 100%;
            height: 200px;
			margin: @padding-mobile auto 0;
			display: flex;
			align-items: center;
            justify-content: center;
            position: relative;

            .maxW(@mobile-max, {
				height: 180px;
			});
        }

        .product-image-wrapper {
            position: static;
        }

		.product-item-sku {
			font-size: 1.4rem;
		}

		.product-item-name {
			font-family: @font-1;
			font-size: 1.4rem;
			line-height: 1.2;

			a {
				display: block;
                font-weight: 900;
                font-family: inherit;
			}

			.minW(@mobile-max, {
				a {
					height: 48px;
					overflow: hidden;
				}
			});
		}

		.product-category {
			font-family: @font-1;
			color: @text-3;
			font-weight: 900;
			text-transform: uppercase;
			font-size: 1.1rem;
			margin-top: 5px;
			height: 2rem;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;

			p {
				text-overflow: ellipsis;
				overflow: hidden;
			}
		}

		.labels {
			position: absolute;
			top: -1rem;
			left: -1rem;
			z-index: 2;
		}

		.actions {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			align-items: center;
			background-color: @main-bg;
			border-top: 1px solid @rgba-black;
            font-size: 1.4rem;
			position: absolute;
			left: -1px;
			right: -1px;
			top: 100%;
			height: @addtocart-h;
			border-left: 1px solid;
			border-right: 1px solid;
			border-color:  @rgba-black;
			transition: transform @transition, opacity @transition, box-shadow @transition;
			transform: translateY(-@addtocart-h);
			transition-delay: .05s;

			.button {
				padding-top: .6rem;
				padding-bottom: .6rem;
			}

			.see-product {
				display: flex;
				width: 100%;
				height: 100%;
				justify-content: center;
				align-items: center;
				color: @text-2;
			}
			.add-to-cart-qty {
				display: flex;
				gap: 10px;
				justify-content: center;
				align-items: center;
				margin: 0 10px;
			}
			.qty {
				padding: 5px;
				border-bottom: 1px solid #ffc200;
				text-align: center;
				min-height: auto;
			}
        }

		.button {
			.button-red();
			.button-small();
			margin: @padding-mini;
		}

        .price-box {
            margin-bottom: 0;
        }

		.minW(@desktop-min, {
			padding-bottom: 0;

			&:hover {
				box-shadow: 0 8px 30px 4px @rgba-shadow;

				.actions {
					z-index: 99;
					transform: translateY(0);
					opacity: 1;
					box-shadow: 0 14px 25px 0 @rgba-shadow;
				}
			}

			.actions {
				opacity: 0;
				z-index: -1;
				border-bottom: 1px solid @border-light;
			}
		});

		.minW(@desktop-lg, {
			.button {
				font-size: 1.2rem;
			}

            .actions {
                font-size: 1.6rem;
            }
		});
	}
}

.product-item-photo {
	display: block;
	border: 0 none;
	flex-basis: 0;
	width: auto;
    height: 300px;
	overflow: hidden;

	img {
		max-width: 100%;
		max-height: 100%;
		width: auto;
		height: auto;
	}
}
