#checkout {
  .opc-block-summary {
    .table-totals {
      .totals {
        &.shipping {
          display: none;
        }
      }
      .totals-tax {
        display: none;
      }
    }
  }
}
