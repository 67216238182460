body.account {
  .login-as-customer {
    display: none;
  }
  &.sales-order-history {
    .left-button-container {
      a.button {
        min-width: 200px;
        text-align: center;
      }
    }
  }
  .orders-history {
    .table-order-items{
      .col{
        &.id {
          @media screen {
            width: 40%;
          }
        }
      }
    }
  }
}
.tt_order-order-prints{

  padding: 50px;
  @media print {
    padding: 0;
    @page { margin: 0; }
    .page-wrapper { margin: 1.6cm; }
  }
  .back-to-orders, .box-actions {
    display: none;
  }
  .lac-notification-sticky {
    display: none;
  }
}
