.rma-form {
  .field {
    //align-items: center;
    margin-bottom: 10px;
    @media (min-width: @mobile-max) {
      display: flex;
    }
    & > {
      label {
        min-width: 150px;
        display: inline-block;
        line-height: 32px;
      }
      div {
        width: 100%;
      }
      input, select, textarea {
        width: 100%;
        min-height: auto;
        border: 1px solid;
        min-width: 100%;
        @media (min-width: @mobile-max) {
          min-width: 300px;
        }
      }
    }
    div.mage-error {
      margin-top: 0;
      font-weight: bold;
    }
    .qty-ordered {
      white-space: nowrap;
      line-height: 32px;
      margin-left: 10px;
      display: inline;
      width: auto;
    }
    .comment {
      color: @text-2;
      font-weight: normal;
      font-style: italic;
    }
  }
  .rma-items {
    margin: 30px 0;
    .item-form {
      padding: 15px;
      border: 1px solid #ccc;
      margin-bottom: 15px;
      position: relative;
      p.product {
        font-size: 1.1em;
        margin-bottom: 15px;
      }
      textarea {
        width: 100%;
      }
      .delete {
        position: absolute;
        font-weight: bold;
        color: #dd0000;
        width: 20px;
        height: 20px;
        right: 0;
        top: 0;
        display: block;
        line-height: 1em;
        text-align: center;
        cursor: pointer;
        font-size: 1.3em;
      }
    }
  }
  .file-upload-container {
    .files {
      margin-top: 15px;
    }
    .file {
      position: relative;
      background: linear-gradient(to right, lightblue 50%, transparent 50%);
      background-size: 200% 100%;
      background-position: right bottom;
      transition:all 1s ease;
      display: flex;
      align-items: center;
      &:before {
        content: "";
        display: block;
        z-index: 2;
        background: url("../images/loader-2.gif") no-repeat 50% 50%;
        background-size: 140px;
        width: 25px;
        height: 25px;
        margin-left: 7px;
      }
      &.done, &.error {
        background: lightgreen;
        &:before {
          display: none;
        }
      }
      &.error {
        background: #dd4444;
      }
      a {
        display: inline-block;
        position: relative;
        padding: 5px 10px;
        color: black;
      }
      .delete {
        position: static;
      }
    }
  }
  .submit {
    text-align: center;
    margin: 30px 0 0;
    .button {
      text-transform: uppercase;
      font-size: 1em;
    }
  }
  .order_id {
    font-size: 1.1em;
  }
}